import React, { useState } from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Modal from "react-bootstrap/Modal"
// import Card from "react-bootstrap/Card"
// IMAGES
import Scott from "./images/scott"
import Wayne from "./images/wayne"
import Richard from "./images/richard"
import Bryan from "./images/bryan"
import Fabian from "./images/fabian"
import Rogelio from "./images/rogelio"
import George from "./images/george"
import Nemanja from "./images/nemanja"
import Jj from "./images/jj"

import aboutStyles from "../components/about.module.css"

const MeetOurTeam = () => {
  const [show, setShow] = useState(false)
  const [member, setMember] = useState({ name: "", bio: "" })

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = (member) => {
    setMember(member)
    setShow(true)
  }

  return (
    <Container className={aboutStyles.meetUs}>
      <Row>
        <Col>
          <h1>
            <span className={aboutStyles.underlineCenter}>Our Leadership</span>
          </h1>
        </Col>
      </Row>

      <Row className={aboutStyles.team}>
        <Col
          className={aboutStyles.teamCard}
          onClick={() =>
            handleShow({
              name: "Wayne Henry",
              bio:
                "Wayne leads and manages all on-line business relationships between our clients, Strategic Alliances, Inc. and all major online retailers such as Amazon and Walmart.com. Wayne constantly surveys the marketplace to anticipate the evolving needs of consumers and our customer. His intuitive insights provide a powerful feedback loop into our client's product development and refinement process helping our clients position their product assortment to always be on the forward edge of the growth curve. His constant involvement with online retailer purchasing and promotions programs to ensure our clients always maximize their growth opportunities. Wayne leads our efforts with clients and customers to ensure we always have the latest products, product information, forecasting, and inventory available for sale. Wayne has over 32 years of sales, consulting, customer relationship management, and project management experience in the CPG and retail industries. Wayne has a deep understanding of the operational and merchandising processes for general merchandise and grocery retailers. Wayne's 17 plus years of sales experience directly with several retail private label groups provides him a unique understanding of the private label management process. Wayne's skills and experience in this area are especially crucial now that most major retailers are putting greater strategic emphasis on the development and growth of their private label brands. Wayne is a 1988 graduate of SUNY New Paltz Business School.",
            })
          }
        >
          <div className={aboutStyles.member}>
            <Wayne />
            <h3>Wayne Henry</h3>
          </div>
        </Col>

        <Col
          className={aboutStyles.teamCard}
          onClick={() =>
            handleShow({
              name: "Scott Bowman",
              bio:
                "Over 35 years of diverse business experience, 29 years of retail and consumer goods industry experience, and 4 years of Wal-Mart buying experience. Scott is a mature, disciplined, and diversely experienced sales, client, and customer relationship leader with a proven ability to deliver outstanding business results to large, small, and entrepreneurial organizations. Scott's 23 years of consulting experience provide him the knowledge and insights needed to be a creative and pragmatic “Team Builder,” skilled at planning, organizing, leading, and executing complex cross-functional sales and operations activities that cross organizational boundaries to quickly deliver business results. Calm under pressure. An empowering leader with poise and executive presence that will ensure successful business relationships at the highest organizational levels. Scott has an inactive CPA status and an MBA from Boston University.",
            })
          }
        >
          <div className={aboutStyles.member}>
            <Scott />
            <h3>Scott Bowman</h3>
          </div>
        </Col>

        <Col
          className={aboutStyles.teamCard}
          onClick={() =>
            handleShow({
              name: "Richard Bowman",
              bio:
                "Richard's credentials include a B.S. in Accounting and Finance from Kansas State in 1990. Richard has an inactive CPA status and 30 years of diverse business experience in both manufacturing and retail, including leadership rolls in Accounting, Systems, Logistics, and Supply Chain. Richard's 10 years with Wal-Mart's Internal Audit, Merchandising Logistics/Supply Chain and Logistics Finance provide a comprehensive understanding of the Retail Supply Chain from origin to shelf. During his 20 years in the Manufacturing industry Richard's responsibilities included Plant Controller, Division Controller, and Director of Information Systems.  Richard currently serves as the CFO for 4 current and 1 past SAI clients. A consistent theme in Richard's career has been the successful application of information technology to solve business problems.",
            })
          }
        >
          <div className={aboutStyles.member}>
            <Richard />
            <h3>Richard Bowman</h3>
          </div>
        </Col>
      </Row>
      <Row className={aboutStyles.team}>
        <Col
          className={aboutStyles.teamCard}
          onClick={() =>
            handleShow({
              name: "Bryan Bergquist",
              bio:
                "Over 15 years of experience in logistics, project management, planning, purchasing, and retail data analysis. Bryan ensure product availability to meet consumer demands for retail, ecommerce, and direct import channels. Bryan leads and manages the sales forecasting & future demand planning, inventory and inventory planning (Space & Equipment Requirements), product flow planning for both domestic and international supply chains, as well as purchasing and tracking in pipeline inventories. Bryan's comprehensive knowledge of product flow through the supply chain from factory to end consumer, his acute analytical skills, and detailed follow up and follow through enable him to design, develop, deploy, and deliver Supply Chain Solutions and Products to customers. Bryan holds a B.S.B.A. in Transportation and Logistics from Sam M. Walton College of Business.",
            })
          }
        >
          <div className={aboutStyles.member}>
            <Bryan />
            <h3>Bryan Bergquist</h3>
          </div>
        </Col>

        <Col
          className={aboutStyles.teamCard}
          onClick={() =>
            handleShow({
              name: "Fabian Pina",
              bio:
                "Fabian joined Strategic Alliances, Inc. upon graduation in 2015 from the University of Arkansas, with a B.S. in Agriculture, Food, & Life Sciences. His experience includes warehouse management, logistics supply chain support, transportation, inventory control, fulfillment, and operations management.  He is intimately familiar with customer/client relationship management - from account and item set up to operational fulfillment.  He is a certified Safe Quality Food (SQF) Practitioner and can aid in the implementation of GFSI systems, policies, and procedures. Fabian oversees the training of new employees and ensures they receive the proper guidance and certification to succeed in their assigned roles and responsibilities.   His advanced communications skills, including oral and written fluency in English and Spanish, are highly valued capabilities that serve Strategic Alliances, Inc., our customers, and clients extremely well.",
            })
          }
        >
          <div className={aboutStyles.member}>
            <Fabian />
            <h3>Fabian Pina</h3>
          </div>
        </Col>

        <Col
          className={aboutStyles.teamCard}
          onClick={() =>
            handleShow({
              name: "Nemanja Vlašković",
              bio:
                "Joined Strategic Alliances International in October 2020. Nemanja earned his bachelor's degree in Business Management from University of Belgrade, Faculty of Organizational Sciences. He has 3 years' experience working as Digital Marketer and Event Manager. Nemanja is currently the Business Process Outsourcing Manager at Strategic Alliances Inc. and oversees daily order processing, load building, routing, and associated operational accounting for multiple clients. Nemanja is fluent in both written and spoken English and former Yugoslavian languages.",
            })
          }
        >
          <div className={aboutStyles.member}>
            <Nemanja />
            <h3>Nemanja Vlašković</h3>
          </div>
        </Col>
      </Row>

      <Row className={aboutStyles.team}>
        <Col
          className={aboutStyles.teamCard}
          onClick={() =>
            handleShow({
              name: "Rogelio Cisneros",
              bio:
                "Joined Strategic Alliances, Inc. June 2015.  During his tenure, while working full-time, he earned an Associates in Business Administration from Northwest Arkansas Community College and will graduate December 2020 with a B.S. in Supply Chain Management from the Sam M. Walton College of Business at the University of Arkansas.  In 5 years, he has worked his way up from Forklift Operator, to Group Leader, to Warehouse Manager.  He currently manages inventory and operations in Strategic Alliances, Inc.'s 50,000 sf warehouse. He is responsible and leads our Order Processing and Inventory Control Specialists. He coordinates and interfaces daily with both customers and clients regarding all issues associated with order management and shipping. Rogelio is fluent in both written and spoken English and Spanish.",
            })
          }
        >
          <div className={aboutStyles.member}>
            <Rogelio />
            <h3>Rogelio Cisneros</h3>
          </div>
        </Col>
        <Col
          className={aboutStyles.teamCard}
          onClick={() =>
            handleShow({
              name: "George Radosavljević",
              bio:
                "George moved to Serbia and joined Strategic Alliances International in December 2020. Originally from South Elgin Illinois, George earned his bachelor's degree in Business Management from Northern Illinois University in June 2020. George has experience in manufacturing, retail, and supply chain management in the United States. George is currently the Dropship Manager at Strategic Alliances Inc. and oversees daily order processing, load building, routing, and associated operational accounting for multiple US clients. George is fluent in both written and spoken English and Serbian.",
            })
          }
        >
          <div className={aboutStyles.member}>
            <George />
            <h3>George Radosavljević</h3>
          </div>
        </Col>
        <Col
          className={aboutStyles.teamCard}
          onClick={() =>
            handleShow({
              name: "Jiajuan Zhong",
              bio:
                "Jiajuan Zhong, the accountant at our company who is dedicated to providing exceptional accounting services to our clients worldwide. Originally from China, JJ moved to the United States to pursue a degree in accounting and finance at the University of Arkansas. With his strong educational background and years of experience in the industry, JJ is committed to helping our clients with accounting and closing of their books. His fluency in both Chinese and English enables him to communicate effectively with clients from diverse backgrounds, making him an essential asset to our team. JJ is also pursuing his MBA at the University of Arkansas to further expand his knowledge and expertise in accounting and finance. With his dedication, professionalism, and passion for accounting, JJ is an invaluable member of our team who is committed to providing our clients with outstanding service",
            })
          }
        >
          <div className={aboutStyles.member}>
            <Jj />
            <h3>Jiajuan Zhong</h3>
          </div>
        </Col>
      </Row>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        dialogClassName={aboutStyles.bioModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{member.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{member.bio}</Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className={aboutStyles.closeButton}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}
export default MeetOurTeam
