import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import aboutStyles from "../about.module.css"

const Scott = () => {
  const data = useStaticQuery(graphql`
  query {
    placeholderImage: file(relativePath: { eq: "scott.jpg" }) {
      childImageSharp {
        fixed(quality: 80, width: 450) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`)
  return (
    <Img
      className={aboutStyles.teamImage}
      fluid={data.placeholderImage.childImageSharp.fixed}
    />
  )
}

export default Scott
